/* global axios */
import store from '@/store';

export default class ServiceService {
  constructor() {
    this.axios = axios;
  }

  all() {
    return this.axios.get('company/partners');
  }
  destroy(id) {
    return this.axios.delete(`company/${id}`);
  }
  get(id) {
    return this.axios.get(`company/partners/${id}`);
  }
  post(id) {
    return this.axios.post(`company/company/${idPartner}`);
  }
}
