export default {
  statusBadge (status) {
    const result = {
      color: '',
      name: ''
    };

    switch (status) {
      case 'active':
        result.name = 'ativo';
        result.color = 'success';
        break;
      case 'inactive':
        result.name = 'desativado';
        result.color = 'primary';
        break;

      case 'disabled':
        result.name = 'desativado';
        result.color = 'primary';
        break;
        
      default:
        result.name = 'desconhecido';
        result.color = 'metal';
    }

    return result;
  }
};
