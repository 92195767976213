<template>
	<div>
		<div class="m-subheader ">
			<div class="d-flex align-items-center">
					<div class="mr-auto">
						<h3 class="m-subheader__title m-subheader__title--separator">Provedores</h3>
						<ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
              <li class="m-nav__item m-nav__item--home">
                <router-link :to="{name: 'home'}" class="m-nav__link m-nav__link--icon">
                  <i class="m-nav__link-icon la la-home"></i>
                </router-link>
              </li>
              <li class="m-nav__separator">-</li>
							<li class="m-nav__item">
								<span class="m-nav__link-text">Gestão de Provedores</span>
							</li>
						</ul>
					</div>
					<div>
						<router-link :to="{name:'create.partner'}" v-if="$authorization.can('module-partners-store')">
							<button
								class="btn btn-outline-danger m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill"
								type="button"
								data-toggle="modal"
								data-target="#company-create"
							>
								<i class="fa fa-plus"></i>
							</button>
						</router-link>
					</div>
			</div>
		</div>
		<div class="m-content">
			<div class="row">
				<div class="col-12">
					<div class="m-portlet m-portlet--full-height">
						<div class="m-portlet__head">
            <div class="m-portlet__head-caption">
              <div class="m-portlet__head-title">
                <h3 class="m-portlet__head-text">
                  Provedores
                </h3>
              </div>
            </div>
            <div class="m-portlet__head-tools pt-3 pb-3">
              <input class="form-control m-input" v-model="search" @keyup="filterPartners" placeholder="Pesquisar...">
            </div>
          </div>
						<div class="m-portlet__body p-5">
							<u-table :columns="columns">
								<tr v-if="isLoading">
									<td colspan="9" class="text-center pt-4 pb-4 m-auto" align="center">
										<div class="m-loader m-loader--danger" style="width: 30px; display: inline-block;"></div>
									</td>
								</tr>
								<tr v-if="!$authorization.can('services') || partnersNotFound || (!isLoading && !partners.length)">
									<td colspan="9" class="text-center pt-4 pb-4 m-auto" align="center">
										Nenhum parceiro cadastrado
									</td>
								</tr>
								<tr v-if="partnersFilter.length" v-for="(partner, partnerIndex) in partnersFilter" :key="partnerIndex">
									<td class="pt-4 pb-4">{{ partner.name || ' - '  }}</td>
									<td class="pt-4 pb-4">{{ partner.key || ' - '  }}</td>
									<td class="pt-4 pb-4">{{ partner.contact_name || ' - ' }}</td>
									<td class="pt-4 pb-4">{{ partner.contact_email || ' - '  }}</td>
									<td class="pt-4 pb-4">
                    <span :class="`m-badge m-badge--${support.statusBadge(partner.status).color} m-badge--wide`">
                      {{ support.statusBadge(partner.status).name }}
                    </span>
                  </td>
									<td class="pt-4 pb-4 text-right">
										<router-link :to="{name: 'home.partner', params: { id: partner.id } }" title="Visualizar" class="mr-4">
											<i class="fa flaticon-cogwheel-2"></i>
										</router-link>
										<router-link :to="{name: 'edit.partner', params: { id: partner.id } }" title="Visualizar" class="mr-4" v-if="$authorization.can('module-partners-update')">
											<i class="fa flaticon-edit"></i>
										</router-link>
										<a href="#delete" data-toggle="modal" title="deletar" id="button-delete" @click="setPartner(partner, partnerIndex)" v-if="$authorization.can('module-partners-destroy')">
												<i class="fa flaticon-delete"></i>
											</a>
									</td>
								</tr>
							</u-table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<u-modal title="Deletar parceiro" id="delete" size="md">
      <div slot="body">
        <p>Deseja realmente excluir este cronograma?</p>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Cancelar</button>
        <button type="button" ref="btnStore" class="btn m-btn btn-primary" data-dismiss="modal" @click="destroy()">Excluir</button>
      </div>
    </u-modal>
</div>
</template>

<script>

import UTable from '@/components/UTable';
import PartnerService from '../services/PartnerService';
import Suport from '../support';
import UModal from '@/components/UModal';

export default {
  name: 'partners',
  components: {
			UTable,
			UModal
  },
  data() {
		return {
			isLoading: true,
		  isCreating: false,
		  support: Suport,
			partnerService: new PartnerService(),
			partners: [],
			partnersFilter: [],
			partnersNotFound: false,
			companiesContent: [],
			page: 1,
			partner: {},
      columns: [
				'Nome',
				'Key',
				'Contato',
				'E-mail contato',
				'Status',
        '',
				
			],
			partnerIndex: '',
			search: '',
		}
	},
	created() {
		this.all();
	},
	methods: {
		all() {
		  return this.partnerService.all(this.page, this.filter)
			.then((response) => {
				this.partners = response.data;
				this.partnersFilter = response.data;
				this.isLoading = false;
			})
			.catch((error) => {
				this.partnersNotFound = true;
				this.isLoading = false;
			  console.log(error.response)
			})
		},
		destroy() {
			axios.delete(`/company/company/${this.partner.id}`)
			.then(response => {
				swal('Sucesso!', 'Empresa excluída!', 'success');
				this.partners.splice(this.partnerIndex, 1);
      })
      .catch(error=> swal('Ops!', 'A empresa não foi deletada', 'error'));
		},
		setPartner(partner, partnerIndex) {
			this.partner = partner;
			this.partnerIndex = partnerIndex;
		},
		filterPartners() {
        const exp = new RegExp(this.search.trim(), 'i');
        this.partnersFilter = this.partners.filter((partner) => {
          let status = '';

          switch(partner.status) {
            case 'active':
              status = 'ativo';
            break;
            case 'inactive':
              status = 'inativo';
            break;
          }

          return exp.test(partner.name) || exp.test(partner.key) || exp.test(status)|| exp.test(partner.contact_email);
        });
      },
	}
}
</script>
